import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface DatetimePickerProps {
    value: string;
    stopEditing: () => void;
}

const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}/${month}/${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
};

const DatetimePicker = forwardRef((props: DatetimePickerProps, ref) => {
    const [selectedDate, setSelectedDate] = useState<string>('');

    useEffect(() => {
        if (props.value) {
            setSelectedDate(props.value);
        }
    }, [props.value]);

    useImperativeHandle(ref, () => ({
        getValue() {
            return selectedDate;
        }
    }));

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawDate = event.target.value;

        console.log(rawDate);

        const formattedDate = formatDateTime(rawDate);

        setSelectedDate(formattedDate);
    };

    return (
        <input
            type='datetime-local'
            value={selectedDate}
            onChange={handleDateChange}
            onBlur={() => props.stopEditing()}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }}
            autoFocus={true} />
    );
});

export default DatetimePicker;
