import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { MemberAirports, MemberPayments, UpdateMemberPayments } from './types';

const GET_AAAN_MEMBER_AIRPORTS_DATA = 'geAAANMemberAirportsData';
const GET_AAAN_MEMBER_AIRPORTS_DATA_OPTIONS = 'geAAANMemberAirportsDataOptions';

const GET_AAAN_MEMBER_PAYMENTS_DATA = 'geAAANMemberPaymentsData';

const base = 'aaan';

// Member Airports
export function useGetMemberAirportsData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<MemberAirports[]>([GET_AAAN_MEMBER_AIRPORTS_DATA], `/${base}/spreadsheet/member-airports`, { ...options, method: 'GET' });
}

export function useGetMemberAirportsDataOptions({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<{ ICAO: string[], XERO_CONTACTS: { XERO_CONTACT_ID: string, XERO_CONTACT_NAME: string }[] }>([GET_AAAN_MEMBER_AIRPORTS_DATA_OPTIONS], `/${base}/spreadsheet/member-airports/options`, { ...options, method: 'GET' });
}

export async function updateMemberAirportsData(data: MemberAirports[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/member-airports/update`, 'POST', data);
}

export async function insertMemberAirportsData(data: MemberAirports[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/member-airports/insert`, 'POST', data);
}

export async function deleteMemberAirportsData(ids: number[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/member-airports/delete`, 'DELETE', ids);
}

// Member Payments
export function useGetMemberPaymentsData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<MemberPayments[]>([GET_AAAN_MEMBER_PAYMENTS_DATA], `/${base}/spreadsheet/member-payments`, { ...options, method: 'GET' });
}

export async function updateMemberPaymentsData(data: UpdateMemberPayments) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/member-payments/update`, 'POST', data);
}

// Prepare for Defence
export async function deletePrepareForDefenceData(ids: number[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/prepare-for-defence/delete`, 'DELETE', ids);
}
