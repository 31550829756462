import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import {
    AircraftAirlineData,
    AircraftAirlineDataOptions,
    AircraftVersionData,
    AirportsCommercialData,
    AirportsCommercialReferenceData,
    AirportsCommercialReferenceDataOptions,
    AirportsScheduleData,
    AirportsScheduleDataOptions
} from './types';

const createQueryString = (queryParams: { [key: string]: string }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString;
};

// commercial
const GET_AIRPORTS_COMMERCIAL_DATA = 'getAirportsCommercialData';

export function useGetAirportsCommercialData(queryParams: { [key: string]: string }, { ...options }: UseApiRequestOptions<any> = {}) {
    const queryString = createQueryString(queryParams);

    return useReactQueryRequest<AirportsCommercialData[]>([`${GET_AIRPORTS_COMMERCIAL_DATA}-${queryString}`], `/airports/spreadsheet/commercial?${queryString}`, { ...options, method: 'GET' });
}

export async function updateAirportsCommercialData(data: AirportsCommercialData[]) {
    return await makeApiRequest<any>('/airports/spreadsheet/commercial/update', 'POST', data);
}

export async function deleteAirportsCommercialRows(ids: number[]) {
    return await makeApiRequest<boolean>('/airports/spreadsheet/commercial/delete', 'DELETE', ids);
}

// commercial reference
const GET_AIRPORTS_COMMERCIAL_REFERENCE_DATA = 'getAirportsCommercialReferenceData';
const GET_AIRPORTS_COMMERCIAL_REFERENCE_DATA_OPTIONS = 'getAirportsCommercialReferenceDataOptions';

export function useGetAirportsCommercialReferenceData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<AirportsCommercialReferenceData[]>([GET_AIRPORTS_COMMERCIAL_REFERENCE_DATA], '/airports/spreadsheet/commercial-reference', { ...options, method: 'GET' });
}

export function useGetAirportsCommercialReferenceDataOptions({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<AirportsCommercialReferenceDataOptions>([GET_AIRPORTS_COMMERCIAL_REFERENCE_DATA_OPTIONS], '/airports/spreadsheet/commercial-reference/options', { ...options, method: 'GET' });
}

export async function updateAirportsCommercialReferenceData(data: AirportsCommercialReferenceData[]) {
    return await makeApiRequest<any>('/airports/spreadsheet/commercial-reference/update', 'POST', data);
}

// schedule
const GET_AIRPORTS_SCHEDULE_DATA = 'getAirportsScheduleData';
const GET_AIRPORTS_SCHEDULE_DATA_OPTIONS = 'getAirportsScheduleDataOptions';

export function useGetAirportsScheduleData(queryParams: { [key: string]: string }, { ...options }: UseApiRequestOptions<any> = {}) {
    const queryString = createQueryString(queryParams);

    return useReactQueryRequest<AirportsScheduleData[]>([`${GET_AIRPORTS_SCHEDULE_DATA}-${queryString}`], `/airports/spreadsheet/schedule?${queryString}`, { ...options, method: 'GET' });
}

export async function getAirportsScheduleData(queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    return await makeApiRequest<AirportsScheduleData[]>(`/airports/spreadsheet/schedule?${queryString}`, 'GET');
}

export function useGetAirportsScheduleDataOptions(queryParams: { [key: string]: string }, { ...options }: UseApiRequestOptions<any> = {}) {
    const queryString = createQueryString(queryParams);

    return useReactQueryRequest<AirportsScheduleDataOptions>([`${GET_AIRPORTS_SCHEDULE_DATA_OPTIONS}-${queryString}`], `/airports/spreadsheet/schedule/options?${queryString}`, { ...options, method: 'GET' });
}

export async function deleteAirporsScheduleRows(ids: number[], queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    return await makeApiRequest<boolean>(`/airports/spreadsheet/schedule/delete?${queryString}`, 'DELETE', ids);
}

export async function addAirportsScheduleRow(data: AirportsScheduleData[], queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    return await makeApiRequest<any>(`/airports/spreadsheet/schedule/insert?${queryString}`, 'POST', data);
}

export async function saveAirportsScheduleData(data: AirportsScheduleData[], queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    const updatedLoadFactorSheet = data.map((data) => ({ ...data, LOAD_FACTOR: parseFloat((data.LOAD_FACTOR / 100).toFixed(4)) }));

    return await makeApiRequest<any>(`/airports/spreadsheet/schedule/update?${queryString}`, 'POST', updatedLoadFactorSheet);
}

export async function duplicateAirportsScheduleScenario(queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    return await makeApiRequest<any>(`/airports/spreadsheet/schedule/duplicate-scenario?${queryString}`, 'POST');
}

export async function duplicateAirportsScheduleRows(ids: number[], queryParams: { [key: string]: string }) {
    const queryString = createQueryString(queryParams);

    return await makeApiRequest<any>(`/airports/spreadsheet/schedule/duplicate-rows?${queryString}`, 'POST', ids);
}

// aircraft airline
const GET_AIRCRAFT_AIRLINE_DATA = 'getAircraftAirlineData';
const GET_AIRCRAFT_AIRLINE_DATA_OPTIONS = 'getAircraftAirlineDataOptions';

export function useGetAircraftAirlineData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<AircraftAirlineData[]>([GET_AIRCRAFT_AIRLINE_DATA], '/referenceaircraft/spreadsheet', { ...options, method: 'GET' });
}

export async function getAircraftAirlineData() {
    return await makeApiRequest<AircraftAirlineData[]>('/referenceaircraft/spreadsheet', 'GET');
}

export async function insertAircraftAirlineData(data: AircraftAirlineData[]) {
    return await makeApiRequest<any>('/referenceaircraft/spreadsheet/insert', 'POST', data);
}

export function useGetAircraftAirlineDataOptions({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<AircraftAirlineDataOptions>([GET_AIRCRAFT_AIRLINE_DATA_OPTIONS], '/referenceaircraft/spreadsheet/options', { ...options, method: 'GET' });
}

export async function updateAircraftAirlineData(data: AircraftAirlineData[]) {
    return await makeApiRequest<any>('/referenceaircraft/spreadsheet/update', 'POST', data);
}

export async function deleteAircraftAirlineData(ids: number[]) {
    return await makeApiRequest<boolean>('/referenceaircraft/spreadsheet/delete', 'DELETE', ids);
}

// versions
const GET_AIRCRAFT_VERSION_DATA = 'getAircraftVersionData';

export function useGetAircraftVersionData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<AircraftVersionData[]>([GET_AIRCRAFT_VERSION_DATA], '/aircraftversion/spreadsheet', { ...options, method: 'GET' });
}

export async function getAircraftVersionData() {
    return await makeApiRequest<AircraftVersionData[]>('/aircraftversion/spreadsheet', 'GET');
}

export async function updateAircraftVersionData(data: AircraftVersionData[]) {
    return await makeApiRequest<any>('/aircraftversion/spreadsheet/update', 'POST', data);
}

export async function deleteAircraftVersionData(ids: number[]) {
    return await makeApiRequest<boolean>('/aircraftversion/spreadsheet/delete', 'DELETE', ids);
}
