import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { CoreType, LoadList, LoadListRequest } from './types';

const base = 'core';

// Production Planning
const GET_CORE_PRODUCTION_PLANNING = 'getCoreProductionPlanning';

export function useGetCoreData(filterType: 'machine' | 'drying' | 'treatmentplant', { ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<CoreType[]>([`${GET_CORE_PRODUCTION_PLANNING}-${filterType}`], `/${base}/spreadsheet?filterType=${filterType}`, { ...options, method: 'GET' });
}

export async function updateCoreData(data: CoreType[], queryParams: { [key: string]: string }) {
    return await makeApiRequest<any>(`/${base}/spreadsheet/update`, 'POST', data, 'application/json', queryParams);
}

// Load list
const GET_CORE_LOAD_LIST = 'getCoreLoadList';

export function useGetLoadListData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<LoadList[]>([GET_CORE_LOAD_LIST], `/${base}/spreadsheet/load-list`, { ...options, method: 'GET' });
}

export async function updateLoadListData(data: LoadListRequest[]) {
    return await makeApiRequest<any>(`/${base}/spreadsheet/load-list/update`, 'POST', data, 'application/json');
}
