import { useState } from 'react';
import Typography from 'app/components/Typography';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import { GridApi } from 'ag-grid-community';
import { deleteMemberAirportsData } from 'app/api/aaan/aaan';

interface DeleteModalProps {
    gridApi: GridApi | null;
    payload: number[];
    refetchDataOptions: any;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ gridApi, payload, refetchDataOptions }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const handleDeleteRows = async () => {
        try {
            setIsDeleting(true);
            await deleteMemberAirportsData(payload);

            if (gridApi) {
                const selectedData = gridApi.getSelectedRows();

                gridApi.applyTransaction({ remove: selectedData });
            }

            await refetchDataOptions();
            clearModalContent();
            setNotification('Rows successfully deleted', 'success');
        } catch (error) {
            setNotification('Error deleting rows', 'error');
        } finally {
            setIsDeleting(false);
            clearModalContent();
        }
    };

    return (
        <div className='flex-column'>
            {payload.length > 0
                ? <Typography className='mb-5'>{`Are you sure you want to delete the selected row${payload.length > 1 ? 's' : ''}?`}</Typography>
                : <Typography className='mb-5'>Please select rows to delete.</Typography>}
            <div className='d-flex justify-content-end gap-3'>
                <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
                {payload.length > 0 && <Button onClick={() => handleDeleteRows()} disabled={isDeleting} variant='danger'
                    size='sm'>Delete</Button>}
            </div>
        </div>
    );
};

const useDeleteRows = (gridApi: GridApi | null, refetchDataOptions: any) => {
    const { setModalContent } = useModalStore();

    const handleDeleteModal = (selectedRows: { ID: number }[]) => {
        const payload = selectedRows.map((row) => row.ID);

        setModalContent({
            content: <DeleteModal gridApi={gridApi} payload={payload} refetchDataOptions={refetchDataOptions} />,
            title: `Delete Row${payload.length > 1 ? 's' : ''}`
        });
    };

    return { handleDeleteModal };
};

export default useDeleteRows;
