import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import useToast from 'app/hooks/useToast';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import AppDatePicker from 'app/components/AppDatePicker';
import { ReportsType } from 'app/api/reports/types';
import { updateAirportsCommercialData, useGetAirportsCommercialData, useGetAirportsCommercialReferenceDataOptions } from 'app/api/airports/airports';
import { CELL_EDITOR_TYPES, ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import { getPreviousMonth } from '../helpers';
import { AirportsCommercialData, AirportsCommercialReferenceDataOptions } from 'app/api/airports/types';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useCreateAirportGridColumns from './hooks/useCreateAirportGridColumns';
import useDeleteRows from './hooks/useDeleteRows';
import useSpreadsheet from '../../hooks/useSpreadsheet';

import './spreadsheet-grid-airports-commercial.scss';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetGridAirportsCommercial = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [monthYearFilter, setMonthYearFilter] = useState<string>(getPreviousMonth());
    const [freshData, setFreshData] = useState<AirportsCommercialData[]>([]);

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetAirportsCommercialData({ monthYear: monthYearFilter },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setFreshData(data.data.data);
            }
        });

    const { data: dataOptions } = useGetAirportsCommercialReferenceDataOptions(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false

        });

    const { setNotification } = useToast();

    const parsedRawSheetData: AirportsCommercialData[] = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);
    const { handleDeleteModal } = useDeleteRows();

    const createdColumns = useCreateAirportGridColumns(parsedRawSheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [parsedRawSheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport parsedRawSheetData={parsedRawSheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, parsedRawSheetData]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        const changedObjects = findChangedObjects(freshData, updatedValues);

        const isIdZero = updatedValues.find((data) => data.ID === 0)!!;

        try {
            setIsSaving(true);
            await updateAirportsCommercialData(isIdZero ? updatedValues : changedObjects);
            refetch();

            setIsSheetUpdated(false);
            setNotification('Sheet saved', 'success');
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setNotification(error.response.data.message, 'error');
            } else {
                setNotification('Failed to update spreadsheet', 'error');
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleAddRowGrid = async () => {
        const newData = columnNames.reduce((acc: { [key: string]: string | number | null }, column) => {
            if (column.field === 'ID') {
                acc[column.field] = null;
            } else if (column.cellEditor === CELL_EDITOR_TYPES.NUMBER) {
                acc[column.field] = 0;
            } else if (column.cellEditor === CELL_EDITOR_TYPES.SELECT) {
                acc[column.field] = dataOptions!.data.data[column.field as keyof AirportsCommercialReferenceDataOptions][0];
            } else if (column.field === 'AIRPORT_DATE') {
                acc[column.field] = monthYearFilter;
            }

            return acc;
        }, {});

        if (gridApi) {
            gridApi.applyTransaction({ add: [newData] })!;

            gridApi.paginationGoToLastPage();
            setNotification('Added a row', 'success');
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
                <AppDatePicker
                    showIcon={true}
                    icon='fa fa-calendar'
                    className='w-200px'
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    placeholderText='Select Month and Year'
                    dateFormat='yyyy-MM'
                    dropdownMode='select'
                    showMonthYearPicker={true}
                    selected={new Date(monthYearFilter)}
                    onChange={(date: Date) => setMonthYearFilter(date ? moment(date).startOf('month').format('YYYY-MM-DD') : getPreviousMonth())} />
            </div>
            <div className={`spreadsheet-grid card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3'>
                        <Button size='sm'
                            onClick={() => handleDeleteModal(gridApi)}>Delete Row</Button>
                        <Button size='sm'
                            onClick={handleAddRowGrid}>Add Row</Button>
                        <Button disabled={isSaving || !isSheetUpdated} size='sm'
                            onClick={handleSaveSpreadsheet}>Save</Button>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        sheetDataOptions={dataOptions ? dataOptions.data.data : { OUTLET_NAME: [], REVENUE_TYPE: [] }}
                        pageSize={pageSize}
                        parsedRawSheetData={parsedRawSheetData}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridAirportsCommercial;
