import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import useToast from 'app/hooks/useToast';
import { LoadList, LoadListRequest } from 'app/api/core/types';
import { updateLoadListData, useGetLoadListData } from 'app/api/core/core';
import useSpreadsheet from '../../hooks/useSpreadsheet';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';

import useCreateLoadListColumns from './hooks/useCreateLoadListColumns';
import '../../spreadsheet-grid.scss';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetGridLoadList = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [freshRecords, setFreshRecords] = useState<LoadList[]>();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();

    const { data: rawSheetData, isLoading: isSpreadsheetDataLoading, refetch } = useGetLoadListData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setFreshRecords(data.data.data);
            }
        });
    const { setNotification } = useToast();

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: any = useMemo(() => rawSheetData ? JSON.parse(JSON.stringify(rawSheetData.data.data)) : [], [rawSheetData]);

    const createdColumns = useCreateLoadListColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport parsedRawSheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node: any) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        if (rawSheetData) {
            const changedObjects = findChangedObjects<LoadList>(freshRecords || [], updatedValues);

            const payload = changedObjects.map((data) => ({
                TLL_LOAD_NO: data.LOAD_LIST_NUMBER,
                SSH_SHIPMENT_NO: data.SHIPMENT_NO,
                PRIORITY: data.PRIORITY,
                READY_BY: data.READY_BY,
                PRINTED: data.PRINTED,
                ISSUED: data.ISSUED,
                PICK_UP_DATETIME: data.PICK_UP_DATETIME,
                NOTES: data.NOTES
            }));

            try {
                setIsSaving(true);
                await updateLoadListData(payload);
                refetch();

                setNotification('Sheet saved', 'success');
                setIsSheetUpdated(false);
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    setNotification(error.response.data.message, 'error');
                } else {
                    setNotification('Failed to update spreadsheet', 'error');
                }
            } finally {
                setIsSaving(false);
            }
        }
    };

    const onSelectionChanged = useCallback(() => {
        if (gridApi) {
            const selectedRows = gridApi.getSelectedRows().length;

            document.querySelector('#selectedRows')!.innerHTML = 'Rows Selected: ' + selectedRows.toString();
        }
    }, [gridApi]);

    return (
        <div>
            <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            <div className={`spreadsheet-grid card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3' >
                        <Button disabled={isSaving || !isSheetUpdated} size='sm'
                            onClick={handleSaveSpreadsheet}>Save</Button>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        parsedRawSheetData={sheetData}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged}
                        onRowSelect={onSelectionChanged} />
                    <div className='d-flex align-items-center justify-content-between my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2' id='selectedRows' />
                        <div className='d-flex align-items-center'><p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                            <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                                <option value='dynamic'>Dynamic</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                                <option value='300'>300</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridLoadList;
